import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    
    {
      path: '/',
      name: 'TableOfMeetings',
      component: () => import('../views/MeetingsTable.vue')
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/Login.vue')
    },
    {
      path: '/registration',
      name: 'Registration',
      component: () => import('../views/Registration.vue')
    },
    {
      path: '/admin',
      name: 'Admin',
      component: () => import('../views/Admin.vue')
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('../views/Error.vue')
    },
    {
      path: '/task',
      name: 'TableOfTasks',
      component: () => import('../views/TasksTable.vue')
    },
    {
      path: '/offline',
      name: 'OfflineMeeting',
      component: () => import('../views/OfflineMeeting.vue')
    },
    {
      path: '/create',
      name: 'Meeting',
      component: () => import('../views/Meeting.vue')
    },
    {
      path: '/edit/:id',
      name: 'MeetingUpdate',
      component: () => import('../views/Meeting.vue')
    },
    {
      path: '/copy/:id',
      name: 'MeetingCopy',
      component: () => import('../views/Meeting.vue')
    },
    {
      path: '/show/:id',
      name: 'MeetingShow',
      component: () => import('../views/Meeting.vue')
    },
    {
      path: '/task/create',
      name: 'Task',
      component: () => import('../views/Task.vue')
    },
    {
      path: '/task/edit/:id',
      name: 'TaskUpdate',
      component: () => import('../views/Task.vue')
    },
    {
      path: '/task/show/:id',
      name: 'TaskShow',
      component: () => import('../views/Task.vue')
    },
    {
      path: '/search',
      name: 'FullTextSearch',
      component: () => import('../views/FullTextSearch.vue')
    },
  ]
})

export default router
