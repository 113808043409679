import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAdmin: false,
    user: null,
    token: null,
  },
  mutations: {
    setUser(state, user) {
      localStorage.setItem('user', user);
      state.user = user;
    },
    setIsAdmin(state, isAdmin) {
      localStorage.setItem('isAdmin', isAdmin);
      state.isAdmin = isAdmin;
    },
    setToken(state, token) {
      localStorage.setItem('token', token);
      state.token = token;
    },
    removeToken(state, token) {
      localStorage.removeItem('token');
      state.token = null;
    },
  },
  actions: {},
  getters: {
    isLoggedIn(state) {
      state.token = localStorage.getItem('token') || '';
      return !!state.token;
    },
    getToken(state) {
      state.token = localStorage.getItem('token') || '';
      return state.token;
    },
    getIsAdmin(state) {
      state.isAdmin = localStorage.getItem('isAdmin') || '';
      return state.isAdmin;
    },
    getUser(state) {
      state.user = localStorage.getItem('user') || '';
      return state.user;
    },
  },
});
